@tailwind base;
@tailwind components;
@tailwind utilities;

/**
* Evitar agregar stylos en css, mejor agregar utilidades en tailwind o con styled components según sea el caso.
**/

/** stylos globales **/
:root {
  --background_color: #212121;
}

html {
  background: var(--background_color);
  color: white;
  box-sizing: border-box;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -webkit-touch-callout: none;
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

table {
  user-select: text; /* supported by Chrome and Opera */
  -webkit-user-select: text; /* Safari */
  -webkit-touch-callout: text;
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text;
}

/* Estilos para los para quitar alineación se hizo así porque tailwind no permite extender align items */
@media (min-width: 1024px) {
  .items-normal {
    align-items: normal;
  }
}

/* Para poner en mayuscula los meses del año de Material UI en los pickers */
.PrivatePickersFadeTransitionGroup-root:first-child {
  text-transform: capitalize;
}

/* Sweet alert overriden */
.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 1300;
}

.swal2-icon.swal2-warning.swal2-icon-show {
  color: #e42823;
  border: 10px solid #e42823;
}

.swal2-title {
  color: black;
}

.swal2-html-container {
  color: #878787;
}

.swal2-cancel.swal2-styled.swal2-default-outline,
.swal2-confirm.swal2-styled.swal2-default-outline {
  text-transform: uppercase;
}

.swal2-actions {
  display: flex;
  flex-direction: row-reverse;
}

.swal2-styled.swal2-confirm {
  background-color: "#009688" !important;
}

/* Botón animado de daq y remote */
.btn {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn.btn-anim {
  position: relative;
  overflow: hidden;
}

.btn.btn-anim span,
.btn.btn-anim svg {
  left: 0;
  position: absolute;
  top: -100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.btn-text {
  opacity: 1;
  transition: all 0.3s ease 0s;
}

.btn.btn-anim:hover .btn-text {
  opacity: 0;
}

.btn.btn-anim:hover span,
.btn.btn-anim:hover svg {
  top: 20%;
}

.over {
  border: 3px dotted #009688;
  background-color: #333;
}

/* Float nav bar -  para que cuando se haga scroll se flote la barra y el icono de perfil */
.floated {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;
  z-index: 1100;
  background-color: #212121;
  -webkit-box-shadow: 0px 7px 17px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 7px 17px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 7px 17px -4px rgba(0, 0, 0, 0.75);
}

.floated-profile {
  position: fixed;
  top: 1%;
  right: 1%;
  z-index: 1100;
}

#transition-popper {
  z-index: 10;
}

.son-height > div {
  height: 100%;
}

/* Icons set */
.icons-set {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

/* unavailable */
.unavailable {
  background: linear-gradient(180deg, #831df5 0%, #46dcfe 100%);
  font-family: "Montserrat", sans-serif;
}
